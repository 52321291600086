/* ReservationsPage.css */

.reservations-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.reservations-header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.reservations-header h1 {
  margin: 0;
  font-size: 2em;
}

.reservations-content {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
}

@media (max-width: 768px) {
  .reservations-header h1 {
    font-size: 1.5em;
  }

  .reservations-content {
    padding: 10px;
  }
}
