.bullet-point-description-container {
	margin-top: 5rem;
	margin-bottom: 5rem;
  width: 100%;
  max-width: 80%;
  box-sizing: border-box;
	/* border: 2px solid rgba(255, 255, 255, 0.3); */
	border-radius: 20px;
	padding: 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	animation: slideUp 1s ease-out forwards;
	box-shadow: 0 4px 15px var(--heading-color);
	z-index: 2;
	background-image: url('./blob-haikei.svg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .bullet-point-description-container {
    max-width: 100%;
  }
}

@keyframes slideUp {
	from {
		transform: translateY(10rem);
	}
	to {
		transform: translateY(0);
	}
}

.bullet-point-description {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
}

.bullet-point-heading {
	position: relative;
  font-size: clamp(4rem, 4vw, 3rem);
  margin: 0 0 1rem 0;
  color: white;
	border-radius: 20px;
	padding: 1rem 3rem;
	text-shadow: 1px 1px 2px white;
  font-family: 'Winky Sans', sans-serif;
	width: fit-content;
	margin: 0 auto;
	margin-bottom: 4rem;
}


.bullet-point-subheading {
  font-size: clamp(1rem, 2vw, 1.5rem);
  color: #030100;
  margin-bottom: 2rem;
  opacity: 0.9;
	font-family: 'Lexend Deca', sans-serif;
}

.bullet-point-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
}

.bullet-point-wrapper {
  height: 100%;
  display: flex;
}

/* Style for the individual bullet point items */
.bullet-point {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1rem;
}

/* Container for the icon */
.bullet-point-icon {
  margin-bottom: 1.5rem;
  width: auto; /* Set width instead of height */
  height: 63px; /* Allow height to adjust naturally */
  display: flex;
  align-items: center;
  justify-content: center;
}

.bullet-point-icon img {
  width: 100%; /* Make image fill the container width */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure image fits within bounds without distortion */
}

/* Title styles */
.bullet-point-title {
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
  min-height: 4.5rem; /* Fixed height for 3 lines of text */
  display: flex;
  align-items: center;
}

/* Description styles */
.bullet-point-text {
  margin: 0;
  flex-grow: 1; /* This will push the text to align with others */
  font-size: 1rem;
  line-height: 1.5;
}

.bullet-point-button {
  background: linear-gradient(135deg, #F6C674, #d0a51e);
  border: none;
  border-radius: 1.5rem;
  padding: 0.8rem 1.5rem;
  color: #030100;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(246, 198, 116, 0.3);
}

.bullet-point-button:hover {
  background: linear-gradient(135deg, #d0a51e, #F6C674);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(246, 198, 116, 0.4);
}

@media (max-width: 768px) {
  .bullet-point-list {
    grid-template-columns: 1fr;
  }

  .bullet-point-description {
    /* margin: 2rem auto; */
    /* padding: 1rem; */
    text-align: center;
  }

  .bullet-point-heading {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    display: block;
    text-wrap: nowrap;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
  }


  .bullet-point-subheading {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    display: block;
  }

  .bullet-point-item {
    padding: 1.5rem;
    width: 90%;
    max-width: 320px;
    min-height: 200px;
  }

  .bullet-point-title {
    font-size: 1.2rem;
    margin-bottom: 0.75rem;
    display: block;
    min-height: auto; /* Remove fixed height on mobile */
  }

  .bullet-point-description-text {
    font-size: 0.95rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 2000px) {
  .bullet-point-description {
    max-width: 1800px;
  }
}
