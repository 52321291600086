:root {
    --primary-color: #010101;
    --secondary-color: #F6C674;
    --background-color: #fff;
    --text-color: #030100;
    --text-color-white: #fff;
    --heading-color: #A5765C;
    --second-background-color: #F6F2EF;
    --button-hover-color: #D0A51E;
    --button-hover-color-black: #363535;
    --desactivated-link: #ccc;
}

