.trainer-list {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
	position: relative;
}

.search-section {
	display: flex;
	justify-content: center;
	align-items: center;
  padding: 1rem;
}

.map-section {
	display: flex;
	justify-content: center;
	align-items: center;
  height: 40vh;
  width: 100%;
}

.map-section .leaflet-container {
	display: flex;
	justify-content: center;
	align-items: center;
  height: 100%;
  width: 84%;
	border-radius: 1rem;
	border: 2px solid;
}

.results-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem;
}

.trainers-container {
  display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 2rem;
}

.loading-state,
.error-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 0;
}

.pagination-button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-button.active {
  background: #e0b127;
  color: white;
  border-color: #e0b127;
}

.pagination-button:hover:not(.active) {
  background: #f4d35e;
}

/* Tablet breakpoint */
@media (min-width: 768px) {
  .trainers-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Desktop breakpoint */
@media (min-width: 1024px) {
  .map-section {
    height: 50vh;
  }

  .trainers-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

/* Large Desktop breakpoint */
@media (min-width: 1280px) {
  .trainers-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Card hover effect */
.trainers-grid > * {
  transition: transform 0.2s ease;
}

.trainers-grid > *:hover {
  transform: translateY(-4px);
}

