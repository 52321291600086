/* Modern Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  background-color: var(--background-color);
  width: 100%;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 10000;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  backdrop-filter: blur(10px);
	font-family: 'Lexend Deca', sans-serif;
	height: 84px;
}

.navbar.floating {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
}

/* Logo Section */
.logo {
  flex-shrink: 0;
}

.logo-image {
  height: 24px;
  width: auto;
  transition: transform 0.3s ease;
}

.logo-image:hover {
  transform: scale(1.05);
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 3rem;
  align-items: center;
  margin: 0 2rem;
}

.nav-link {
  font-size: 0.95rem;
  color: #000;
  text-decoration: none;
  position: relative;
  padding: 0.5rem 0;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #000;
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

/* Buttons */
.buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.btn {
  padding: 0.7rem 1.5rem;
  border: none;
  border-radius: 50px;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
	font-family: 'Lexend Deca', sans-serif;
}

.btn.outline {
  background: transparent;
  border: 2px solid #000;
  color: #000;
}

.btn.filled {
  background: #000;
  color: #fff;
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.btn.outline:hover {
  background: #000;
  color: #fff;
}

/* User Menu */
.user-menu {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.profile-pic {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.profile-pic:hover {
  transform: scale(1.05);
}

/* Dropdown Menu */
.dropdown {
  position: relative;
}

.dropbtn {
  background: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: transform 0.3s ease;
}

.dropdown-content {
  position: absolute;
  right: 0;
  top: 120%;
  background: #fff;
  min-width: 200px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
}

.dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-content a,
.dropdown-content button {
  padding: 0.8rem 1.2rem;
  color: #000;
  text-decoration: none;
  display: block;
  font-size: 0.95rem;
  text-align: left;
  background: none;
  border: none;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-content a:hover,
.dropdown-content button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Mobile Menu */
.menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
}

/* Simplified Mobile Menu Styles (since animations are handled by Framer Motion) */
.menu-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
	border-radius: 10px;
	margin-top: 10px;
}

.menu-item {
  padding: 1rem 2rem;
  background: #fff;
}

.menu-item a,
.menu-item button {
  color: #000;
  text-decoration: none;
  font-size: 1rem;
  width: 100%;
  display: block;
}

.menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.menu-item.logout {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}

/* Remove any existing transition properties as they're now handled by Framer Motion */
.menu-icon,
.menu-content,
.menu-item {
  transition: none;
}

@media (max-width: 1024px) {
  .navbar {
    padding: 1rem 2rem;
		border-radius: 0 0 10px 10px;
  }

  .menu-icon {
    display: block;
  }

  .nav-links,
  .buttons {
    display: none;
  }
}

.menu-content a {
  color: #000 !important;
  text-decoration: none !important;
}
