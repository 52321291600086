.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
	margin-top: 84px;
}


@media (max-width: 768px) {
  .content {
    padding: 10px;
  }
}
