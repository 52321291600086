/* ReservationList.css */

.reservation-list-container {
  padding: 20px;
  font-family: sans-serif;
  color: #333;
}

.reservation-legend {
  margin-bottom: 40px;
}

.reservation-legend h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.reservation-legend p {
  font-size: 1em;
  color: #555;
  margin-bottom: 15px;
}

.legend-items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 0.9em;
  color: #333;
}

.legend-items div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.booking-section {
  margin-bottom: 40px;
}

.booking-section h3 {
  font-size: 1.4em;
  margin-bottom: 5px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.section-description {
  font-size: 0.9em;
  color: #777;
  margin-bottom: 20px;
}

.reservation-card {
  background-color: #fff;
  border-left: 5px solid #5cb85c;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  transition: transform 0.1s;
}

.reservation-card:hover {
  transform: scale(1.01);
}

.reservation-card-header {
  background-color: #f7f7f7;
  padding: 15px 20px;
  position: relative;
}

.reservation-card-header h3 {
  margin: 0;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  gap: 5px;
}

.reservation-card-body {
  padding: 15px 20px;
  font-size: 0.95em;
}

.reservation-card-body p {
  margin: 10px 0;
}

.trainer-link {
  color: #0275d8;
  text-decoration: none;
}

.trainer-link:hover {
  text-decoration: underline;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 12px;
  color: #fff;
  font-size: 0.8em;
  text-transform: uppercase;
  position: absolute;
  right: 20px;
  top: 15px;
}

.status-badge.pending,
.status-badge.pending_reschedule {
  background-color: #f0ad4e;
}

.status-badge.validated,
.status-badge.confirmed {
  background-color: #5cb85c;
}

.status-badge.rescheduled {
  background-color: #0275d8;
}

.status-badge.declined,
.status-badge.cancelled_by_pro,
.status-badge.cancelled_by_client {
  background-color: #6c757d;
}

.reservation-card.pending,
.reservation-card.pending_reschedule {
  border-left-color: #f0ad4e;
}

.reservation-card.rescheduled {
  border-left-color: #0275d8;
}

.reservation-card.declined,
.reservation-card.cancelled_by_pro,
.reservation-card.cancelled_by_client {
  border-left-color: #999;
  opacity: 0.8;
}

.reservation-actions {
  margin-top: 15px;
}

.reservation-actions button {
  padding: 8px 16px;
  background-color: #d9534f;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.reservation-actions button:hover {
  background-color: #c9302c;
}

.no-action {
  color: #999;
  font-style: italic;
}

.past-disabled {
  filter: grayscale(100%);
  cursor: default;
  pointer-events: none;
}

.loading-message,
.no-bookings-message {
  color: #555;
  font-size: 1em;
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .reservation-card-body p {
    font-size: 0.9em;
  }
  .reservation-card-header h3 {
    font-size: 1em;
  }
  .legend-items {
    gap: 10px;
    flex-direction: column;
  }
}
