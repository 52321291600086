body {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  margin: 0;
  padding: 0;
}

.login-container {
  display: flex;
  min-height: 100vh;
}

.login-image {
  flex: 1;
  background: url('https://images7.alphacoders.com/368/thumb-1920-368333.jpg') no-repeat center center;
  background-size: cover;
}

.login-form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  background-color: #fff;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
}

.login-form-container h2,
.login-form-container h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
  text-align: center; /* Centrer le texte */
  font-weight: normal; /* Enlever le gras */
}

.brand-name {
  font-family: 'Arial', sans-serif; /* Utiliser une police différente si besoin */
  font-style: italic;
}

.login-form-container form {
  display: flex;
  flex-direction: column;
  margin-left: 6rem;
  margin-right: 6rem;
}

.login-form-container label {
  font-size: 1em;
  margin-bottom: 5px;
}

.login-form-container input {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

.login-form-container a {
  margin-bottom: 20px;
  color: #ff6600;
  text-decoration: none;
}

.login-form-container a:hover {
  text-decoration: underline;
}

.login-form-container .login-button {
  background-color: #333 !important; /* Couleur de fond noir */
  color: #fff !important; /* Couleur du texte blanc */
  margin-bottom: 20px;
}

.login-form-container .create-account {
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
}

.separator {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.separator span {
  padding: 0 10px; /* Ajuste l'espacement horizontal */
  white-space: nowrap; /* Empêche le texte de se diviser en plusieurs lignes */
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: #ddd;
}
