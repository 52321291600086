/* AboutPage.css */

.about-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 3rem; /* Espace entre les sections */
  /* Un petit background en filigrane (optionnel)
     background: url('../assets/dog-pattern.svg') no-repeat center/cover;
     opacity: 0.05;
  */
}

.section-card {
  background-color: #fff;  /* Garder un fond blanc ou très légèrement coloré */
  border-radius: 8px;      /* Légèrement arrondi pour donner un aspect "carte" */
  box-shadow: 0 0 10px rgba(0,0,0,0.05); /* Ombre très douce */
  padding: 2rem;
  /* Animation fade-in au scroll (optionnel) */
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s forwards;
  /* Pour faire apparaître la carte quand on la voit
     On utilisera IntersectionObserver en JS, ou un simple "onScroll" library
     pour déclencher .visible {opacity:1; transform:translateY(0);} */
}

.section-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.section-icon {
  width: 24px; 
  height: 24px;
  opacity: 0.8;
}

.section-card h1,
.section-card h2 {
  margin: 0;
  color: #333;
}

.section-card p,
.section-card li {
  color: #555;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.section-card ul {
  list-style: none; /* pas de puce, on peut ajouter une mini-icône à la place si on veut */
  padding-left: 1rem;
}

.section-card li::before {
  content: "• ";
  color: #999; /* Couleur discrète pour la puce */
}

/* Animation Keyframes (simple fade in + slide up) */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
