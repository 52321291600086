/* src/components/BulletPoint/BulletPoint.css */

.bullet-point-item {
  margin-top: 2rem;
  background-color: #f8f3e8;
  border-radius: 100px 100px 0 100px;
  padding: 30px;
  width: 250px;
  min-height: 280px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px var(--heading-color);
  transition: all 0.3s ease;
	border: 3px solid var(--heading-color);
	color: var(--heading-color);
	font-family: 'Lexend Deca', sans-serif;
}

.bullet-point-item:hover {
  border-radius: 100px;
	box-shadow: 0 0 25px 5px var(--heading-color);
	transform: translateY(-10px);
	border: 4px solid var(--heading-color);
}

.bullet-point-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}


.bullet-point-description-text {
  font-size: 1rem;
  color: #030100;
  margin-bottom: 20px;
}

/* Media Queries pour la responsivité */
@media (max-width: 768px) {
  .bullet-point-item {
    width: 80%;
    min-height: auto;
  }
}
