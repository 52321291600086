.dog-background-container {
  min-height: 80vh;
}

.dog-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 6rem;
    margin-bottom: 2rem;
    
  }
  
  .dog-details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .dog-details img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .dog-details h3 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .dog-details p {
    margin: 5px 0 0;
    color: #555;
  }
  
  .add-dog-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 20px 0;
    background-color: var(--secondary-color);
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .add-dog-button:hover {
    background-color: var(--secondary-color);
  }


  .delete-icon {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
  }
  .cancel-button {
    background-color: #5bc0de;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .dog-info {
    flex-grow: 1; /* Make sure the text takes up available space */
    margin-left: 20px;
  }


  .confirm-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  
  .confirm-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    z-index: 1001;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }

  .confirm-modal-content .button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px; /* Ajoute un espace entre les boutons */
}

.confirm-modal-content .confirm-button {
    background-color: #d9534f; /* Rouge pour confirmer */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirm-modal-content .cancel-button {
    background-color: #ccc; /* Couleur neutre pour annuler */
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirm-modal-content .confirm-button:hover {
    background-color: #c9302c; /* Rouge foncé au survol */
}

.confirm-modal-content .cancel-button:hover {
    background-color: #bbb; /* Gris plus foncé au survol */
}

.edit-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  margin-right: 10px; /* Ajustez l'espacement selon vos besoins */
}
  
  
  