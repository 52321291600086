.faq-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 6rem auto;
  padding: 2rem;
  gap: 4rem;
	font-family: 'Lexend Deca', sans-serif;
}

.faq-content-wrapper {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 4rem;
  align-items: start;
}

.faq-section {
  background: var(--secondary-color);
  border-radius: 24px;
  padding: 2rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
}

.faq-section h2 {
  font-size: 2.5rem;
  color: #6f4e37;
  margin-bottom: 2rem;
  font-weight: 700;
	font-family: 'Winky Sans', sans-serif;
}

.faq-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  overflow: hidden;
}

.faq-question {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #6f4e37;
  font-weight: 600;
  font-size: 1.1rem;
}

.faq-answer {
  padding: 0 1.5rem;
  color: #8b6b53;
  line-height: 1.6;
}

.faq-image-section {
  position: sticky;
  top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0;
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 24px;
}

.faq-image {
  width: 100%;
  border-radius: 24px 24px 0 0;
  aspect-ratio: 3/4;
  object-fit: cover;
  display: block;
}

.faq-cta-container {
background-color: #A5765C;
opacity: 1;
background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #A5765C 40px ), repeating-linear-gradient( #f6c67455, #f6c674 );
  border-radius: 0 0 24px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
	box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
}

.faq-cta-text {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
	font-family: 'Winky Sans', sans-serif;
}

.faq-cta-button {
  background: linear-gradient(135deg, #e0b127 0%, #ffd45e 100%);
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  isolation: isolate;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow:
    0 1px 2px rgba(224, 177, 39, 0.4),
    0 4px 8px rgba(224, 177, 39, 0.3),
    0 8px 16px rgba(224, 177, 39, 0.2),
    inset 0 2px 2px rgba(255, 255, 255, 0.4),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
	font-family: 'Lexend Deca', sans-serif;
}

.faq-cta-button::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, transparent 100%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.faq-cta-button:hover {
  background: linear-gradient(135deg, #edc23a 0%, #ffd970 100%);
  transform: translateY(-3px);
  box-shadow:
    0 2px 4px rgba(224, 177, 39, 0.4),
    0 8px 16px rgba(224, 177, 39, 0.3),
    0 16px 32px rgba(224, 177, 39, 0.2),
    inset 0 2px 2px rgba(255, 255, 255, 0.4),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
}

.faq-cta-button:hover::before {
  opacity: 1;
}

.faq-cta-button:active {
  transform: translateY(-1px);
  box-shadow:
    0 1px 2px rgba(224, 177, 39, 0.4),
    0 4px 8px rgba(224, 177, 39, 0.3),
    inset 0 2px 2px rgba(255, 255, 255, 0.4),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
  .faq-content-wrapper {
    grid-template-columns: 1fr;
  }

  .faq-image-section {
    position: relative;
    top: 0;
  }
}

@media (max-width: 768px) {
  .faq-container {
    margin: 3rem auto;
    padding: 1rem;
  }

  .faq-section h2 {
    font-size: 2rem;
  }

  .faq-question {
    font-size: 1rem;
    padding: 1rem;
  }

  .faq-answer {
    padding: 0 1rem 1rem;
  }
}
