.thank-you-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Prend 80% de la hauteur de la fenêtre */
    text-align: center;
    padding: 20px;
  }
  
  .thank-you-container h2 {
    font-size: 2.5rem;
    color: #333; /* Couleur du titre */
    margin-bottom: 1rem;
  }
  
  .thank-you-container p {
    font-size: 1.2rem;
    color: #555; /* Couleur du texte */
    line-height: 1.6;
    max-width: 600px; /* Limite la largeur du texte pour le centrage */
    margin: 0 auto;
  }
  
  /* Optionnel : pour rendre la page responsive sur mobile */
  @media (max-width: 768px) {
    .thank-you-container h2 {
      font-size: 2rem;
    }
  
    .thank-you-container p {
      font-size: 1rem;
    }
  }
  