/* ======================= */
/*  Conteneur principal    */
/* ======================= */

.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  max-width: 1200px;
  width: 100%;
  position: relative;
  z-index: 1000;
	height: 100%;
}

.search-bar-container.with-margin {
  margin-top: 2rem;
}

/* ======================= */
/*  La SearchBar elle-même */
/* ======================= */

.search-bar {
  display: flex;
  flex-wrap: nowrap;
	overflow: visible;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 0.5rem;
  width: auto;
  /* max-width: 100%; */
  box-sizing: border-box;
	height: 84%;
}

/* ======================= */
/*     Champ input + label */
/* ======================= */

.search-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
  position: relative; /* pour dropdown si nécessaire */
  min-width: 200px; /* Add minimum width */
}

.search-input-container label {
  font-weight: bold;
  font-size: 0.9rem;
  margin-left: 0.7rem;
  white-space: nowrap;
  color: #333;
}

.search-input {
  padding: 0.4rem 0.7rem;
  border: none;
  border-radius: 20px;
  outline: none;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.2;
}

.search-input::placeholder {
  color: #777;
}

/* ======================= */
/*   Bouton Rechercher     */
/* ======================= */



.search-btn {
  background: linear-gradient(135deg, #e0b127 0%, #ffd45e 100%);
  color: black;
  border: none;
  margin-left: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  isolation: isolate;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow:
    0 1px 2px rgba(224, 177, 39, 0.4),
    0 4px 8px rgba(224, 177, 39, 0.3),
    0 8px 16px rgba(224, 177, 39, 0.2),
    inset 0 2px 2px rgba(255, 255, 255, 0.4),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
	font-family: 'Lexend Deca', sans-serif;
}

.search-btn::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, transparent 100%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.search-btn:hover {
  background: linear-gradient(135deg, #edc23a 0%, #ffd970 100%);
  transform: translateY(-3px);
  box-shadow:
    0 2px 4px rgba(224, 177, 39, 0.4),
    0 8px 16px rgba(224, 177, 39, 0.3),
    0 16px 32px rgba(224, 177, 39, 0.2),
    inset 0 2px 2px rgba(255, 255, 255, 0.4),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
}

.search-btn:hover::before {
  opacity: 1;
}

.search-btn:active {
  transform: translateY(-1px);
  box-shadow:
    0 1px 2px rgba(224, 177, 39, 0.4),
    0 4px 8px rgba(224, 177, 39, 0.3),
    inset 0 2px 2px rgba(255, 255, 255, 0.4),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
}
/* ======================= */
/*   Le séparateur ( | )   */
/* ======================= */

.separator {
  height: 40px;
  width: 1px;
  background-color: #ccc;
  margin: 0 0.4rem;
}

/* ======================= */
/*      Suggestions        */
/* ======================= */

.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-width: 250px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border-radius: 1rem;
  z-index: 1000;
  margin-top: 5px;
}

.suggestions-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0; /* Remove default padding */
}

.suggestion-category {
  /* border-bottom: 1px solid #eee; */
  list-style: none;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
}

.suggestion-header {
  padding: 8px 12px;
  background-color: #333;
  color: white;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
}

.suggestion-items {
  list-style: none;
  margin: 0;
  padding: 0; /* Remove default padding */
}

.suggestion-items li {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.suggestion-items li:hover {
  background-color: #f4f4f4;
}

.suggestion-type {
  margin-left: 0.3rem;
  font-size: 0.8rem;
  color: #999;
}

.no-results-message {
  padding: 0.5rem 0.7rem;
  color: #999;
}

/* ======================= */
/*   Message d'erreur      */
/* ======================= */

.search-error-message {
  margin-top: 0.5rem;
  color: #b94a48;
  background-color: #f8d8d5;
  border: 1px solid #f3c7c5;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  max-width: 600px;
  margin: 0.5rem auto;
  text-align: center;
}

/* ======================= */
/*        Responsive       */
/* ======================= */

@media (max-width: 768px) {
  .search-bar {
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    width: 90%; /* Change from 180% to 90% */
    margin: 0 auto;
  }

  .search-input-container {
    margin-left: 0;
    margin-bottom: 1rem;
    width: 100%;
  }

  .search-btn {
    border-radius: 20px;
    margin-left: 0;
    width: 100%;
    justify-content: center;
    padding: 0.6rem;
    font-size: 1rem;
  }

  .suggestions-dropdown {
    position: absolute; /* Keep absolute positioning */
    width: 100%;
    min-width: unset; /* Remove minimum width on mobile */
		z-index: 1001 !important;
  }

  .search-input-container {
    position: relative;
    z-index: 1;
  }
}

@media (max-width: 768px) {
  .separator {
    display: none !important;;
  }
}

/* Add these styles for Google Places Autocomplete */
.pac-container {
  border-radius: 6px;
  margin-top: 5px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  font-family: inherit;
  z-index: 1000 !important;
}

.pac-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
}

.pac-item:hover {
  background-color: #f4f4f4;
}

.pac-item-query {
  font-size: 14px;
  color: #333;
}
