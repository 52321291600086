/* src/components/Footer/Footer.css */
.footer {
  background-color: #272626;
  color: #fff;
  padding: 40px 20px;
	font-family: 'Lexend Deca', sans-serif;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

/* Zone supérieure : copyright et réseaux sociaux */
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(197, 196, 196, 0.2);
}

.footer-top p {
  font-size: 0.9rem;
  color: #c5c4c4;
}

.social-media {
  display: flex;
  gap: 20px;
}

.social-media a {
  color: #c5c4c4;
  transition: all 0.3s ease;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(197, 196, 196, 0.2);
}

.social-media a:hover {
  color: #fff;
  border-color: #fff;
  transform: translateY(-2px);
}

/* Zone inférieure : liens de navigation */
.footer-links-container {
  padding-top: 10px;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 25px;
  justify-items: center;
  text-align: center;
}

.footer-links a {
  color: #c5c4c4;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  position: relative;
}

.footer-links a:hover {
  color: #fff;
  text-decoration: none;
  transform: translateX(5px);
}

.footer-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #fff;
  transition: width 0.3s ease;
}

.footer-links a:hover::after {
  width: 100%;
}

/* Responsive */
@media (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  .footer-links {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
  }
}
