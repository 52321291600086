.cgv-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cgv-modal .MuiDialog-paper {
  max-height: 66vh;
  margin: 0;
  position: relative;
  top: 0;
  transform: none;
}

.cgv-modal .MuiDialogContent-root {
  padding: 20px 24px;
  overflow-y: auto;
}

.cgv-modal .MuiDialogTitle-root {
  padding: 16px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.cgv-modal .MuiDialogActions-root {
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
