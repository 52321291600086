.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 4rem;
  min-height: 80vh;

}

.contact-page h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.contact-page p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}