.terms-of-use {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    min-height: 80vh;
    margin-top: 2rem;
  }
  
  .terms-of-use h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .terms-of-use h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  
  .terms-of-use p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .terms-of-use ul {
    list-style-type: disc;
    padding-left: 40px;
    margin-bottom: 20px;
  }
  
  .terms-of-use li {
    margin-bottom: 10px;
  }
  