.hero-container {
  display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border: 1px solid transparent;
  /* box-shadow:
    inset 0 0 30px rgba(255, 255, 255, 0.6),
    inset 0 0 84px rgba(255, 255, 255, 0.6); */
  position: relative;
  overflow: hidden; /* To ensure animation stays within container */
  min-height: 100vh; /* Ensure container takes up full viewport height */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
	justify-content: center;
  position: relative;
  z-index: 3;
  text-align: center;
	height: 450px;
	width: 450px;

	background-color: var(--secondary-color);

	/* opacity: 1;
	background-image:
    radial-gradient(circle at 21% 21%, var(--heading-color) 0%, rgba(165, 118, 92, 0.3) 20%, transparent 40%),
    radial-gradient(circle at 84% 84%, var(--heading-color) 0%, rgba(165, 118, 92, 0.3) 20%, transparent 40%);
	background-size: 100% 100%;
	background-position: center; */

	/* background-color: var(--secondary-color); */
/*
	background-image: url('./logo-rtt.png');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat; */

	border-radius: 50%;
	gap: 42px;
}


@media (max-width: 768px) {
  .hero-content {
    height: 300px;
    width: 300px;
		background-color: transparent;
		gap: 20px;
  }
}

.hero-content h2 {
  font-size: 2.8rem;
  color: white;
	margin: 0;
  line-height: 1.4;
	width: 520px;
	text-align: center;
	z-index: 2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	font-family: 'Winky Sans', sans-serif;
}

@media (max-width: 768px) {
	.hero-content h2 {
		font-size: 1.8rem;
		width: 320px;
	}
}

.styled-hero-text {
  color: var(--secondary-color);
	font-weight: 700;
	background-color: var(--background-color);
	padding: 0.25rem 0.5rem;
	border-radius: 0.5rem;
	box-shadow: 0 0 10px 1px var(--heading-color);
	border: 1px solid white;
	text-shadow: none;
}


.hero-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

/* Add new styles for social proof positioning and animation */
.social-proof-main-container {
  width: 100%;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
}
