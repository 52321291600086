@import "leaflet/dist/leaflet.css";
@import 'typeface-lato';


body {
  margin: 0;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 400; /* Regular */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content {
	padding: 0 !important;
	overflow-x: hidden;
}


