/* Corps global */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  margin: 0;
  padding: 0;
}

/* Container principal */
.signup-container {
  display: flex;
  min-height: 100vh;
}

/* Colonne gauche : Image plein écran */
.signup-image {
  flex: 1;
  background: url('https://images7.alphacoders.com/368/thumb-1920-368333.jpg') no-repeat center center;
  background-size: cover;
}

/* Colonne droite : Formulaire */
.signup-form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  background-color: #fff;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
}

.form-content {
  display: grid;
  gap: 20px;
  margin: 0 auto; /* On centre horizontalement */
  max-width: 400px; /* Largeur max */
  width: 100%;
}

/* Titres */
.signup-form-container h2,
.signup-form-container h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
  text-align: center;
  font-weight: normal;
}

/* Bannière d'erreur globale */
.form-global-error {
  background-color: #fff3cd; /* un jaune doux */
  color: #856404;
  border: 1px solid #ffeeba;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

/* Champs du formulaire */
form {
  display: flex;
  flex-direction: column;
}

.form-field {
  margin-bottom: 20px;
}

.form-field label {
  display: block;
  font-size: 1em;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-field input,
.form-field select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  box-sizing: border-box;
}

/* Si le champ a une erreur, on le surligne en rouge */
.input-error {
  border-color: #e74c3c;
  background-color: #fef2f2;
}

/* Petit message d'erreur en-dessous du champ */
.field-error-message {
  color: #e74c3c;
  font-size: 0.9em;
  margin-top: 5px;
}

/* Bouton s'inscrire */
.signup-button {
  background-color: #333;
  color: #fff;
  padding: 10px;
  border: none;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1em;
  transition: background-color 0.2s ease;
}

.signup-button:hover {
  background-color: #555;
}

.login-button,
.skip-button {
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1em;
}

.login-button:hover,
.skip-button:hover {
  background-color: #f0f0f0;
}

/* Séparateur au milieu */
.separator {
  position: relative;
  text-align: center;
  margin: 25px 0;
  height: 20px;
}

.separator hr {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0;
  border: none;
  border-top: 1px solid #e0e0e0;
  z-index: 1;
}

.separator span {
  display: inline-block;
  position: relative;
  padding: 0 15px;
  background-color: white;
  color: #555;
  font-size: 16px;
  z-index: 2;
}

/* Responsive */
@media (max-width: 768px) {
  .signup-container {
    flex-direction: column;
  }

  .signup-image {
    height: 200px;
  }

  .signup-form-container {
    padding: 20px;
    box-shadow: none; /* retirez éventuellement l'ombre sur mobile */
  }

  .form-content {
    margin: 0 auto;
    max-width: 95%;
  }
}
