/* GeneralSearch.css */

/* Conteneur principal */
.general-search-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 80vh;
}

/* Styles de la section */
.section-container {
  margin-bottom: 4rem;
}

.section-title {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2rem;
  color: #333;
  position: relative;
}

.section-title::after {
  content: '';
  width: 60px;
  height: 4px;
  background-color: var(--secondary-color);
  display: block;
  margin: 0.5rem auto 0;
  border-radius: 2px;
}

/* Conteneur des cartes */
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 2rem;
  align-items: stretch;
}

/* Styles des cartes */
.card {
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Transition pour adoucir le changement de flou */
  transition: filter 0.3s ease;
}

/* Effets de flou progressifs */
.trainer-1 {
  filter: none;
}

.trainer-2 {
  filter: blur(2px);
}

.trainer-3 {
  filter: blur(4px);
}

.trainer-4 {
  filter: blur(6px);
}

/* Bouton "Voir plus" */
.see-more-btn-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.see-more-btn {
  padding: 0.75rem 1.5rem;
  background-color: var(--secondary-color);
  color: #080808;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.see-more-btn:hover {
  background-color: #e0b127;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Ajustements réactifs */
@media (max-width: 768px) {
  .general-search-container {
    padding: 1rem;
  }

  .section-title {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.5rem;
  }
}
