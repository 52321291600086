.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
	background-image: url('./blurry-gradient-haikei.svg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 210px rgba(255, 255, 255, 0.6);
	margin-top: -84px;
}

@media (max-width: 768px) {
  .home-page {
    box-shadow: none;
  }
}

.hero-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border: 1px solid transparent;
  /* box-shadow:
    inset 0 0 30px rgba(255, 255, 255, 0.6),
    inset 0 0 84px rgba(255, 255, 255, 0.6); */
}

.styled-hero-text {
  color: var(--secondary-color);
	font-weight: 700;
	background-color: var(--background-color);
	padding: 0.25rem 0.5rem;
	border-radius: 0.5rem;
	box-shadow: 0 0 10px 1px var(--heading-color);
	border: 1px solid white;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.yellow-circle {
  background-color: var(--secondary-color);
  border-radius: 50%;
  width: 320px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
  position: relative;
  margin-top: -5rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.yellow-circle h2 {
  font-size: 2rem;
  color: #191919;
  margin-bottom: -3rem;
  line-height: 1.4;
}

.search-bar-wrapper-home {
  margin-top: 20px;
}

/* ======================= */
/*        Responsive       */
/* ======================= */

