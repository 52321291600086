/* ResetPasswordPage.css */

  
  .reset-password-container {
    max-width: 400px;
    margin: 40px auto; /* centre le container */
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    text-align: center;
  }
  
  .reset-password-container h2 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
    font-weight: normal;
  }
  
  .reset-password-container form {
    display: flex;
    flex-direction: column;
  }
  
  .reset-password-container label {
    text-align: left;
    margin-bottom: 5px;
    font-weight: 500;
    color: #555;
  }
  
  .reset-password-container input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .reset-password-container button {
    background-color: #333;
    color: #fff;
    padding: 12px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    transition: background-color 0.2s ease;
  }
  
  .reset-password-container button:hover {
    background-color: #555;
  }
  
  @media (max-width: 480px) {
    .reset-password-container {
      margin: 20px auto;
      padding: 15px;
      width: 90%;
    }
  
    .reset-password-container input {
      font-size: 1em;
    }
  }
  