/* PasswordForgottenPage.css */

/* On peut inclure une police depuis Google Fonts ou autre.
   Exemple : 
   @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
*/


.password-forgotten-container {
  max-width: 400px;
  margin: 40px auto; /* Centrer sur la page */
  padding: 20px;
  background-color: #fff; /* Fond blanc */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
}

.password-forgotten-container h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333; 
  font-weight: normal;
}

.password-forgotten-container form {
  display: flex;
  flex-direction: column;
}

.password-forgotten-container label {
  text-align: left;
  margin-bottom: 5px;
  font-weight: 500;
  color: #555;
}

.password-forgotten-container input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

.reset-button {
  background-color: #333; 
  color: #fff;
  padding: 12px;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.2s ease;
}

.reset-button:hover {
  background-color: #555;
}

/* Petites optimisations sur mobile */
@media (max-width: 480px) {
  .password-forgotten-container {
    margin: 20px auto;
    padding: 15px;
    width: 90%;
  }

  .password-forgotten-container input {
    font-size: 1em;
  }
}
