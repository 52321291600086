.trainer-card {
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 400px;
  margin: 20px;
}

.trainer-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.trainer-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #e0b127;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.trainer-card-image-wrapper {
  width: 100%;
  height: 180px;
  overflow: hidden;
  position: relative;
}

.trainer-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.trainer-card:hover .trainer-card-image {
  transform: scale(1.05);
}

.trainer-card-content {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.trainer-card-header {
  margin-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 12px;
}

.trainer-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin: 0 0 4px 0;
}

.trainer-card-ville {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
  display: flex;
  align-items: center;
}

.trainer-card-services {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
}

.trainer-card-service {
  height: 61px;
  padding: 12px;
  background: linear-gradient(145deg, #ffffff, #f5f5f5);
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.trainer-card-service:hover {
  transform: translateX(5px);
  background: linear-gradient(145deg, #f8f8f8, #ffffff);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.trainer-card-service.placeholder {
  background: linear-gradient(145deg, #f0f0f0, #e8e8e8);
  border: 1px solid rgba(0, 0, 0, 0.03);
  color: #999;
}

.service-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-name {
  font-size: 0.95rem;
  color: #333;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.service-icon {
  color: #e0b127;
  font-weight: bold;
  font-size: 1.1rem;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.trainer-card-service:hover .service-icon {
  opacity: 1;
  transform: translateX(0);
}

.service-availability {
  display: flex;
  align-items: center;
  gap: 6px;
}

.availability-dot {
  width: 6px;
  height: 6px;
  background-color: #4CAF50;
  border-radius: 50%;
  display: inline-block;
}

.availability-text {
  font-size: 0.85rem;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.trainer-card-service:hover .availability-text {
  color: #333;
}

/* Add subtle animation for placeholder cards */
.trainer-card-service.placeholder {
  position: relative;
  overflow: hidden;
}

.trainer-card-service.placeholder::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.trainer-card-service.placeholder.full-width {
  height: 134px; /* Approximately the height of two service cards + gap */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.trainer-card-service.placeholder.full-width .service-content {
  text-align: center;
	justify-content: center;
	align-items: center;
}
