.social-proof-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
	font-family: 'Winky Sans', sans-serif;
}

.social-proof-container h2 {
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.profile-images {
  display: flex;
  gap: -15px; /* Negative gap for overlapping effect */
}

.profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid white;
  background-size: cover;
  background-position: center;
  margin-right: -15px; /* Creates overlapping effect */
}

.rating {
  display: flex;
  gap: 4px;
}

.star {
  font-size: 24px;
}


@media (max-width: 768px) {

	.social-proof-container h2 {
		font-size: 18px;
	}

  .profile-image {
    width: 40px;
    height: 40px;
  }

  .star {
    font-size: 18px;
  }
}
