.modal-update-profile-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  
  .modal-update-profile-content {
    background: #fff;
    padding: 25px;
    border-radius: 15px;
    width: 90%;
    max-width: 500px;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal-update-profile-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #444;
    border-bottom: 3px solid var(--secondary-color);
    padding-bottom: 10px;
  }
  
  .modal-update-profile-content .form-group {
    margin-bottom: 15px;
  }
  
  .modal-update-profile-content .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #444;
  }
  
  .modal-update-profile-content .form-group input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease, background-color 0.3s ease;
  }
  
  .modal-update-profile-content .form-group input:focus {
    border-color: var(--secondary-color);
    background-color: #fff;
    outline: none;
  }
  
  .modal-update-profile-content .button-group {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 20px;
  }
  
  .modal-update-profile-content .button-group .save-button {
    width: calc(50% - 7.5px);
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background: linear-gradient(135deg, var(--secondary-color), #ffdd44);
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .modal-update-profile-content .button-group .save-button:hover {
    background: linear-gradient(135deg, #e6b800, #e6cc33);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .modal-update-profile-content .button-group .cancel-button {
    width: calc(50% - 7.5px);
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background-color: #ccc;
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .modal-update-profile-content .button-group .cancel-button:hover {
    background-color: #bbb;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  