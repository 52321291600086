/**************************************
 * Structure principale
 **************************************/

/* Conteneur global de la page, même structure que ReservationsPage */
.forfaits-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: sans-serif; /* Police simple et lisible */
  color: #333;
  margin-top: 3rem;
}

/* Header sobre avec un fond sombre */
.forfaits-header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.forfaits-header h1 {
  margin: 0;
  font-size: 2em;
}

/* Zone de contenu avec fond clair */
.forfaits-content {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
}

/**************************************
 * État de connexion, erreurs, loader
 **************************************/

/* Messages lorsque l’utilisateur n’est pas connecté ou qu’il n’y a pas de forfait */
.auth-message,
.error-message,
.empty-message {
  text-align: center;
  margin-top: 30px;
  color: #666;
}

/* Erreur plus marquée en rouge */
.error-message {
  color: #c00;
}

/* Loader circulaire minimaliste */
.loading-spinner {
  margin: 50px auto;
  border: 6px solid #eee;
  border-top: 6px solid #333;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% { transform: rotate(360deg); }
}

/**************************************
 * Liste de forfaits (cartes)
 **************************************/

.forfaits-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Carte Forfait */
.forfait-card {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 1.2rem 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.08);
  transition: box-shadow 0.2s;
}

.forfait-card:hover {
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.5rem;
}

.card-header h2 {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
}

/**************************************
 * Badge de statut
 **************************************/

.status-badge {
  padding: 4px 10px;
  border-radius: 9999px; /* Effet pill */
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 0.85rem;
  white-space: nowrap;
}

/* Exemples de couleurs en fonction du statut */
.status-badge.active {
  background-color: #28a745; /* Vert */
}

.status-badge.expired {
  background-color: #dc3545; /* Rouge */
}

.status-badge.used_up {
  background-color: #ffc107; /* Jaune */
}

.status-badge.pending {
  background-color: #f0ad4e; /* Orange */
}

/* Ajoutez d'autres classes si besoin, ex: .status-badge.annule etc. */

/**************************************
 * Description et formateur
 **************************************/

.description {
  margin: 0.5rem 0 1rem;
  font-style: italic;
  color: #555;
}

.trainer-item {
  margin: 0.5rem 0;
}

.trainer-link {
  color: #0275d8;
  text-decoration: none;
}

.trainer-link:hover {
  text-decoration: underline;
}

/**************************************
 * Table des services inclus
 **************************************/

.services-section {
  margin-top: 16px;
}

.services-section h4 {
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 600;
  color: #555;
}

.services-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 8px;
  background-color: #fff;
}

.services-table th,
.services-table td {
  padding: 10px;
  border-bottom: 1px solid #eee;
  text-align: left;
}

.services-table th {
  background-color: #f5f5f5;
}

.services-table tbody tr:hover {
  background-color: #f9f9f9;
}

/* Aucun service inclus */
.no-services {
  color: #777;
  font-style: italic;
}

/**************************************
 * Responsive
 **************************************/

@media (max-width: 768px) {
  .forfaits-header h1 {
    font-size: 1.6em;
  }

  .forfaits-content {
    padding: 10px;
  }

  .card-header h2 {
    font-size: 1.1rem;
  }
}
