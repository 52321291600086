.profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  margin-top: 3rem;
  min-height: 80vh;
}

.alert-banner {
  background-color: #f7f7f7;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.profile-container h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Par défaut, on utilise un layout column,
   ce qui facilite l’adaptation pour le mobile */
.profile-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px; /* On laisse un peu d'espace sous la photo */
}

.profile-info h3 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.profile-info p {
  margin: 5px 0 0;
  color: #757575;
}

/* Bouton : position normale par défaut */
.edit-profile-button {
  margin-top: 20px;
  padding: 8px 16px;
  background-color: var(--secondary-color);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.edit-profile-button:hover {
  background-color: #c99e23;
}

/* Sur desktop (large screens), on repasse en row et on positionne le bouton en haut à droite */
@media screen and (min-width: 601px) {
  .profile-details {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative; 
  }

  .profile-photo {
    margin-right: 20px;
    margin-bottom: 0; 
  }

  .edit-profile-button {
    position: absolute;
    top: 20px;
    right: 20px;
    margin-top: 0;
  }
}
